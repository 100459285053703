import ImagenesTitulo from "./imagenesTitulo";
import TextoTitulo from "./textoTitulo";
import BotonLaunchApp from "./BotonLaunchApp";

const Titulo = () => {

    return (
        <div className="Titulo">
            <TextoTitulo/>
            <ImagenesTitulo/>
            <BotonLaunchApp/>
        </div>
    )
}

export default Titulo;