import ImagenesDescripcion from "./imagenesDescripcion";
import TextoDescripcion from "./textoDescripcion";

const Descripcion = () => {

    return (
        <div className="Descripcion">
            <ImagenesDescripcion/>
            <TextoDescripcion/>
        </div>
    )
}

export default Descripcion;