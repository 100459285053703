import Descripcion from "./descripcion";
import HeaderInicio from "./headerInicio";
import MainBlock from "./mainBlock";
import Titulo from "./titulo";


const ContentsInicio = () => {

    return (
        <div className="ContentsInicio">
            <HeaderInicio/>
            <img className="Vector5" src="../Imagenes/vector2.png"/>
            <MainBlock/>
            <div className="columna">
                <Titulo/>
                <Descripcion/>
            </div>
        </div>
    )
}

export default ContentsInicio;