import { useState, useEffect } from 'react';
import axios from 'axios';
import React from 'react';
import Moment from 'react-moment';

const Transparency = () => {

    const [transactions, setTransactions] = useState([]);
    const [dateFormat, setDateFormat] = useState(0);
    const [stringFormat, setStringFormat] = useState(0);

    let tableTransactions;
    let listTransactions;
    let stringDate;

    useEffect(() => {
        axios.get('https://api-optimistic.etherscan.io/api?module=account&action=txlist&address=0x0d9606fF84A568cd04dFdf412c8c835A7f2Ff712&page=1&offset=7&sort=desc&apikey=FTKYVTMHHNGCUC5XT1EQERQE53WXB2VT88')
            .then(response => {
                setTransactions(response.data.result);
            });
    }, []);

    const StringFormat = () => {
        if(!dateFormat){
            return 'Date Time (UTC)';
        } else {
            return 'Age';
        }
    }

    const TimeStamp = ((value) => {
        const unixTimestamp = Number(value.value);
        if(!dateFormat){
            return (    
                <Moment unix format="YYYY-MM-D HH:mm:ss">{unixTimestamp}</Moment>
            );
        } else {
            return (
                <Moment unix durationFromNow>{unixTimestamp}</Moment>
            );
        }
        
    })

    if (transactions.length > 0) {
        
        listTransactions = transactions.map((data) => (

            <tr className="trTransparency" key={data.hash}>
                <td><a href={'https://optimistic.etherscan.io/tx/'+ data['hash']} className="linksTransaction" target='_blank'>{data.hash.substring(0,16)+"..."}</a></td>
                <td><p className='method'>{data.functionName == "" ? "Transfer" : data.functionName[0].toUpperCase() + data.functionName.substring(1).split('(')[0]}</p></td>
                <td><a href={'https://optimistic.etherscan.io/block/'+ data['blockNumber']} className="linksTransaction" target='_blank'>{data.blockNumber}</a></td>
                <td><TimeStamp value={data.timeStamp} /></td>
                <td>{data.from.substring(0,16)+"..."}</td>
                <td><a href={'https://optimistic.etherscan.io/address/'+ data['to']} className="linksTransaction" target='_blank'>{data.to.substring(0,16)+"..."}</a></td>  
            </tr>
        ));
    }
    
        tableTransactions = (
            <div>
        <table className="tablaTransparency">
            <thead>
                <tr className="tituloTablaTransparency">
                    <th>Txn Hash</th>
                    <th>Method</th>
                    <th>Block</th>
                    <th><a onClick={() => {setDateFormat(!dateFormat)}} className="linksTransaction"><StringFormat/></a></th>
                    <th>From</th>
                    <th>To</th>
                </tr>
            </thead>
            <tbody>
                {listTransactions}
            </tbody>
        </table>

        </div>
        )

    return (
        <div className="Transparency">
            <p className="tituloTransparency">Transparency</p>
            <p className="textoTransparency">Where the tokens are invested?</p>
            <div className='divTransactions'>
                {tableTransactions}
            </div>
            <a href="https://optimistic.etherscan.io/address/0x0b1f0c40305ea9227b96d0d77f0fc42ce2ade0d5" className="enlaceTxn" target="_blank">Full Transaction List</a>
        </div>
    )
}

export default Transparency;