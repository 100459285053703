

const ImagenesTitulo = () => {

    return (
        <div className="ImagenesTitulo">
            <img className="imagen" src="../Imagenes/imagen.png"/>
            <img className="gato2" src="../Imagenes/gato2.png"/>
        </div>
    )
}

export default ImagenesTitulo;