
const Blog = () => {

    return (
        <div className="Blog">
            <p className="tituloBlog">Blog</p>
            <svg className="vectorBlog" width="40" height="2" viewBox="0 0 40 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="2" rx="1" fill="#E6007E"/>
            </svg>
            <p className="fechaBlog">Aug 26, 2022</p>
            <a href="https://medium.com/@growi.fi/introducing-growi-lp-c8a788347bda" className="DescripcionBlog" target="_blank" rel="noopener noreferrer">Introducing Growi.LP</a>
            <p className="textoBlog">Growi.fi is a platform in the DeFi space. Our first product, Growi.LP, automate the Liquidity Provider process decreasing dramatically the impermanent loss risk - so you deposit your stablecoin in our protocol and we get the best opportunities for you, whilst helping the DeFi community proving liquidity to different exchanges in different blockchains.</p>
            <img className="logo2" src="../Imagenes/logoGrowi2.png"/>
        </div>
    )
}

export default Blog;