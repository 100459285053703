import HeaderBotonesInicio from "./headerBotonesInicio";
import MenuMovil from "./MenuMovil";

const HeaderInicio = () => {

    return (
        <div className="HeaderInicio">
            <img className="log" src="../Imagenes/logoPequeño.png"/>
            <HeaderBotonesInicio/>
            <MenuMovil/>
        </div>
    )
}

export default HeaderInicio;