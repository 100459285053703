import ActionsInicio from "./ActionsInicio";
import ConnectInicio from "./ConnectInicio";
import LanguageInicio from "./LanguageInicio";

const HeaderBotonesInicio = () => {

    return (
        <div className="HeaderBotonesInicio">
            <ConnectInicio/>
            <LanguageInicio/>
            <ActionsInicio/>
        </div>
    )
}

export default HeaderBotonesInicio;