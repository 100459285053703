
const TextoTitulo = (props) => {

    return (
        <div className="TextoTitulo">
            <p className="h3">A new way to invest in crypto</p>
            <svg className="vector6" width="57" height="2" viewBox="0 0 57 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.31282" width="56.2095" height="2" rx="1" fill="#F0E400"/>
            </svg>
            <p className="parrafo">a DeFi protocol where you just deposit your stablecoin and enjoy the profits.</p>
        </div>
    )
}

export default TextoTitulo;