import './Inicio.css';
import React from 'react';
import BackgroundInicio from './ComponentsInicio/backgroundInicio';
import ContentsInicio from './ComponentsInicio/contentsInicio';
import WhyUs from './ComponentsInicio/WhyUs';
import Transparency from './ComponentsInicio/Transparency';
import Blog from './ComponentsInicio/Blog';

export default function Inicio() {
    return (
        <div className='Inicio'>
        <BackgroundInicio/>
        <ContentsInicio/>
        <WhyUs/>
        <Transparency/>
        <Blog/>
      </div>
    )
}