
const ImagenesDescripcion = () => {

    return (
        <div className="ImagenesDescripcion">
            <img className="mobile" src="../Imagenes/mobile.png"/>
        </div>
    )
}

export default ImagenesDescripcion;