
const LaunchApp = () => {

    return (

        <div style={{width: 253, height: 148}} className="LaunchApp">
            <button onClick={() => {window.location.href = `#`}} className="botonApp">Launch App <br/> (Coming soon)</button>
            <svg className="vector2" width="276" height="148" viewBox="0 0 276 148" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_805_8573)">
            <path d="M2.5 59.996C2.5 28.5851 27.9636 3.12158 59.3745 3.12158H216.626C248.036 3.12158 273.5 28.5851 273.5 59.996V88.2471C273.5 119.658 248.036 145.122 216.626 145.122H59.3745C27.9636 145.122 2.5 119.658 2.5 88.2471V59.996Z" stroke="#FFC1A0" strokeWidth="5"/>
            </g>
            <defs>
            <filter id="filter0_b_805_8573" x="-169.119" y="-168.497" width="614.237" height="485.237" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="84.5593"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_805_8573"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_805_8573" result="shape"/>
            </filter>
            </defs>
            </svg>
        </div>

)
}

export default LaunchApp;